import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { RepoServices } from 'src/app/service/repo.service';

import { ResetSelectedRepo } from '../../../store/repo.action';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { xml2json, json2xml } from 'xml-js';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { combineLatest } from 'rxjs';
import * as jsondiffpatch from 'jsondiffpatch';
import { UploadSbomComponent } from './components/upload-sbom/upload-sbom.component';

@Component({
  selector: 'app-repo-table, demo-accordion-animation',
  templateUrl: './repo-table.component.html',
  // templateUrlt: './animated.html',
  styleUrls: ['./repo-table.component.scss'],
  // selector: 'demo-dropdown-animation',
  // selector: 'demo-accordion-basic',
  // templateUrl: './basic.html',
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class RepoTableComponent implements OnInit, OnDestroy {
  modalRef?: BsModalRef;
  formatModalRef?: BsModalRef;
  constructor(
    private api: RepoServices,

    private store: Store<{ repoDetail: any }>,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private FormatemodalService: BsModalService,
    private router: Router,
    private _clipboardService: ClipboardService,
    private toaster: ToastrService,
    private http: HttpClient
  ) {}
  oneAtATime = true;
  selectedRepodetail: any = null;
  selectedSource: any = '';
  updateAt_dateFormate: any = new Date();
  repos: any = [];
  selectedFormat: string = '';
  formatetingLoading = true;
  getRepoLoading: any = true;
  selectedurl = '';
  selectedFileUrl = '';
  selectedShareableFormat = '';
  shareAbleRepo: any = null;
  grypeisLoading = false;
  shareAbleUrl = ''; // need to remove
  SBOMshareAbleUrl = '';
  CycloneDXshareAbleUrl = '';
  CycloneDXshareAbleID = '';
  SWIDshareAbleUrl = '';
  SPDXshareAbleUrl = '';
  shareAbleFormate = ['CycloneDX', 'SWID', 'SPDX'];
  shareAbleStopButtonTest = true;
  shareAbleID: any = null;
  JSONview: any = '';
  JSONviewLoading: Boolean = false;
  urlStatusLoading = false;
  urlRegenerateLoading = false;
  newChangesAlertValue = false;
  processingAlertValue: boolean = true;
  fileFormate = '';
  isDisabled = true;
  versionHistoryData: any = [];
  orginalVersionFile: any = null;
  updateVersionFile: any = null;
  webhookUrl = '';
  jsonFileViewerLoading: Boolean = true;

  formatObject(obj: any, level: any) {
    if (level >= 10 || typeof obj !== 'object' || obj === null) {
      return obj;
    }
    const result: any = {};

    for (const key in obj) {
      result[key] = this.formatObject(obj[key], level + 1);
    }

    return result;
  }

  compareObjects(oldObj: any, newObj: any, level: any, parentList: any) {
    if (level >= 10) {
      return newObj;
    }

    const resultObj: any = {};

    for (const key in oldObj) {
      if (newObj && newObj.hasOwnProperty(key)) {
        if (
          typeof oldObj[key] === 'object' &&
          typeof newObj[key] === 'object'
        ) {
          const nestedResult = this.compareObjects(
            oldObj[key],
            newObj[key],
            level + 1,
            parentList
          );
          if (nestedResult === '[Object, Object]') {
            resultObj[key] = JSON.stringify(oldObj[key]);
          } else {
            if (typeof nestedResult === 'object' && nestedResult !== null) {
              resultObj[key] = this.compareObjects(
                oldObj[key],
                newObj[key],
                level + 1,
                parentList
              );
            } else {
              resultObj[key] = nestedResult;
            }
          }
        } else if (oldObj[key] !== newObj[key]) {
          resultObj[key] = `<span style="background:#fafc81">${JSON.stringify(
            newObj[key]
          )}</span>`;
        } else {
          resultObj[key] = newObj[key];
        }
      } else {
        resultObj[key] = `<span style="background:#eb7f89">${JSON.stringify(
          oldObj[key]
        )}</span>`;
      }
    }

    for (const key in newObj) {
      if (oldObj && !oldObj.hasOwnProperty(key)) {
        resultObj[key] = `<span style="background:#81fc9c">${JSON.stringify(
          newObj[key]
        )}</span>`;
      }
    }

    return this.formatObject(resultObj, 0);
  }

  generateRandomAlphaNumericText(length: any) {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomText = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomText += charset[randomIndex];
    }

    return randomText;
  }

  deleteProjectHandler(id: any) {
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you want to delete this Project',
      icon: 'warning',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        const removeProject = this.repos.filter((repo: any) => repo.id !== id);
        this.repos = removeProject;

        let obj = { id };
        this.api.httpDeleteRepo(obj).subscribe(
          (resp: any) => {
            this.toaster.success('Project Deleted Successfully');
          },
          (error: any) => {
            //console.log(error);
          }
        );
      }
    });
  }

  startGrypeScan(id: any) {
    let obj = {
      id: id,
    };
    this.api.httpGrypeScan(obj).subscribe(
      (resp: any) => {
        this.toaster.success('Scan Started Successfully');
      },
      (error: any) => {
        this.toaster.error('Some thing went wrong');
      }
    );
  }
  startGrantScan(id: any) {
    let obj = {
      id: id,
    };
    this.api.httpGrantScan(obj).subscribe(
      (resp: any) => {
        this.toaster.success('Scan Started Successfully');
      },
      (error: any) => {
        this.toaster.error('Some thing went wrong');
      }
    );
  }

  processingAlertHandler(value: boolean) {
    this.processingAlertValue = value;

    var getElementValue = $('#documentChangesAlert').is(':checked');

    let obj = {
      id: this.shareAbleRepo.id,
      obj: { documentProcessingAlert: value },
    };

    let updateRepo = {
      ...this.shareAbleRepo,
      documentProcessingAlert: value,
      documentChangesAlert: getElementValue,
    };

    const findIndexedRepo = this.repos.findIndex(
      (repo: any) => repo.id === this.shareAbleRepo.id
    );

    this.repos[findIndexedRepo] = updateRepo;

    this.api.httpAlertSetting(obj).subscribe(
      (resp: any) => {
        this.toaster.success('Alert Settings are updated now');
      },
      (error) => {
        this.formatetingLoading = false;
        this.toaster.error('Some thing went wrong');
      }
    );
  }
  changesAlertHandler(value: boolean) {
    this.processingAlertValue = value;

    var getElementValue = $('#documentProcessingAlert').is(':checked');

    let obj = {
      id: this.shareAbleRepo.id,
      obj: { documentChangesAlert: value },
    };

    let updateRepo = {
      ...this.shareAbleRepo,
      documentChangesAlert: value,
      documentProcessingAlert: getElementValue,
    };

    const findIndexedRepo = this.repos.findIndex(
      (repo: any) => repo.id === this.shareAbleRepo.id
    );

    this.repos[findIndexedRepo] = updateRepo;

    this.api.httpAlertSetting(obj).subscribe(
      (resp: any) => {
        this.toaster.success('Alert Settings are updated now');
      },
      (error) => {
        this.formatetingLoading = false;
        this.toaster.error('Some thing went wrong');
      }
    );
  }
  changesWebhookConfigureHandler(id: any) {
    let obj = {
      id: id,
      obj: { webhookConfigure: true },
    };

    this.api.httpAlertSetting(obj).subscribe(
      (resp: any) => {
        //console.log('Alert Settings are updated now');
      },
      (error) => {
        this.formatetingLoading = false;
        this.toaster.error('Some thing went wrong');
      }
    );
  }

  changesButtonModify() {
    let obj = {
      id: this.shareAbleRepo.id,
      obj: { newChanges: false },
    };

    this.api.httpAlertSetting(obj).subscribe(
      (resp: any) => {},
      (error) => {
        this.toaster.error('Some thing went wrong');
      }
    );
  }

  newProjectNavigateHandler() {
    this.router.navigate([`/dashboard/sbomdocument`]);
  }
  formateGenerateHandler() {
    let repo = this.selectedRepodetail;
    const randomAlphaNumericString = this.generateRandomAlphaNumericText(20);
    //console.log(repo, "repository")
    let obj = {
      access_token: repo.access_token,
      userName: repo.userName,
      repoName: repo.repoName,
      SBOMshareAbleID: randomAlphaNumericString,
      CycloneDXshareAbleID: randomAlphaNumericString,
      SWIDshareAbleID: randomAlphaNumericString,
      SPDXshareAbleID: randomAlphaNumericString,
      repoUpdateAt: repo.createdAt,
      defalutBranch: repo.defalutBranch,
    };

    if (repo.source === 'github') {
      this.api.httpGenerateFormat(obj).subscribe(
        (resp: any) => {
          const existingItemIndex = this.repos.findIndex(
            (item: any) => item.repoName === resp.result.repoName
          );

          if (existingItemIndex !== -1) {
            this.repos[existingItemIndex] = {
              ...resp.result,
              // loading: false,
              id: resp.result._id,
            };
          } else {
            this.repos.push({
              ...resp.result,
              id: resp.result._id,
              // loading: false,
            });
          }
        },
        (error: any) => {
          this.formatetingLoading = false;
          if (error.error.message === 'Could not generate SBOM') {
            this.toaster.error('Could not generate SBOM from this repository');
          } else {
            this.toaster.error('Unable to generate SBOM');
          }
          
          // Remove the repo from the list if there's an error
          this.repos = this.repos.filter((item: any) => item.repoName !== obj.repoName);
        }
        
      );
    } else if (repo.source === 'gitlab') {
      // console.log(repo, 'repo')
      let obj = {
        access_token: repo.access_token,
        projectID: repo.projectId,
        branchName: repo.defalutBranch,
        source: repo.source,
        repoName: repo.repoName,
        userName: repo.userName,
        url: repo.url,
        SBOMshareAbleID: randomAlphaNumericString,
        CycloneDXshareAbleID: randomAlphaNumericString,
        SWIDshareAbleID: randomAlphaNumericString,
        SPDXshareAbleID: randomAlphaNumericString,
        repoUpdateAt: repo.createdAt,
        defalutBranch: repo.defalutBranch,
        gitlab_name: repo.gitlab_name,
      };

      this.api.httpGenerateGitlabFormat(obj).subscribe(
        (resp: any) => {
          const existingItemIndex = this.repos.findIndex(
            (item: any) => item.repoName === resp.result.repoName
          );

          if (existingItemIndex !== -1) {
            this.repos[existingItemIndex] = {
              ...resp.result,
              // loading: false,
              id: resp.result._id,
            };
          } else {
            this.repos.push({ ...resp.result, id: resp.result._id });
          }
        },
        (error) => {
          this.formatetingLoading = false;
          this.toaster.error('Some thing went wrong');
        }
      );
    } else if (repo.source === 'bitbucket') {
      let obj = {
        appPassword: repo?.access_token,
        branchName: repo?.branchName,
        source: repo?.source,
        repoName: repo?.repoName,
        userName: repo?.userName,
        workspace: repo?.workspaceName,
        url: repo?.url,
        SBOMshareAbleID: randomAlphaNumericString,
        CycloneDXshareAbleID: randomAlphaNumericString,
        SWIDshareAbleID: randomAlphaNumericString,
        SPDXshareAbleID: randomAlphaNumericString,
        repoUpdateAt: repo.createdAt,
        defalutBranch: repo.defalutBranch,
      };

      this.api.httpGenerateBitbucketFormat(obj).subscribe(
        (resp: any) => {
          const existingItemIndex = this.repos.findIndex(
            (item: any) => item.repoName === resp.result.repoName
          );

          if (existingItemIndex !== -1) {
            this.repos[existingItemIndex] = {
              ...resp.result,
              // loading: false,
              id: resp.result._id,
            };
          } else {
            this.repos.push({ ...resp.result, id: resp.result._id });
          }
        },
        (error) => {
          this.formatetingLoading = false;
          this.toaster.error('Some thing went wrong');
        }
      );
    }
  }

  shareAbleLinkHandler(format: any) {
    this.selectedShareableFormat = format;
    let makeurl = this.shareAbleRepo[`${format}shareAbleID`];

    let url = `${environment.uiUrl}fileViewer/${makeurl}/${format}`;
    this.shareAbleUrl = url;
  }

  shareAbleCopyHandler(format: any) {
    this._clipboardService.copy(this.shareAbleUrl);
    this.toaster.success('Link Copied');
  }
  getAllRepos() {
    this.api.httpAllRepos().subscribe(
      (resp: any) => {
        this.getRepoLoading = false;
        if (resp.result.length) {
          resp.result.forEach((element: any) => {
            let obj = {
              access_token: element.access_token,
              createdAt: moment(element.createdAt).format('ll'),
              repoName: element.repoName,
              userName: element.userName,
              SBOMurl: element.SBOMurl,
              workspaceName: element.workspaceName,
              cycloneDXurl: element.cycloneDXurl,
              SWIDurl: element.SWIDurl,
              sbomFileUrl: element.sbomFileUrl,
              cycloneDxFileUrl: element.cycloneDxFileUrl,
              swidFileUrl: element.swidFileUrl,
              SPDXurl: element.SPDXurl,
              spdxFileUrl: element.spdxFileUrl,
              newChanges: element.newChanges,
              url: element.url,
              source: element.source,
              format: element.format,
              id: element._id,
              SBOMshareAbleID: element.SBOMshareAbleID,
              CycloneDXshareAbleID: element.CycloneDXshareAbleID,
              SWIDshareAbleID: element.SWIDshareAbleID,
              SPDXshareAbleID: element.SPDXshareAbleID,
              CycloneDXshareAble: element.CycloneDXshareAble,
              SBOMshareAble: element.SBOMshareAble,
              SPDXshareAble: element.SPDXshareAble,
              SWIDshareAble: element.SWIDshareAble,
              documentProcessingAlert: element.documentProcessingAlert,
              repoUpdateAt: element.repoUpdateAt,
              documentChangesAlert: element.documentChangesAlert,
              webhookConfigure: element.webhookConfigure,
              branchName: element.branchName,
              grypeOutputUrl: element.grypeOutputUrl,
              projectName: element.projectName,
            };
            const existingItemIndex = this.repos.findIndex(
              (item: any) => item.repoName === obj.repoName
            );

            if (existingItemIndex !== -1 && obj.access_token) {
              this.repos[existingItemIndex] = {
                ...obj,
                loading: true,
              };
            } else {
              this.repos.push(obj);
            }
          });
        }
      },
      (error: any) => {
        //console.log(error);
      }
    );
  }

  linkActiveHandler(format: string) {
    this.urlStatusLoading = true;
    const body: any = {};

    body[`${format}shareAble`] = !this.shareAbleRepo[`${format}shareAble`];

    this.shareAbleRepo = { ...this.shareAbleRepo, ...body };

    const findIndexedRepo = this.repos.findIndex(
      (repo: any) => repo.repoName === this.shareAbleRepo.repoName
    );
    this.repos[findIndexedRepo] = this.shareAbleRepo;

    this.api.httpURLvalidate({ ...body, id: this.shareAbleRepo.id }).subscribe(
      (resp: any) => {
        this.urlStatusLoading = false;
      },
      (error: any) => {
        this.toaster.error('Something went wrong');
      }
    );
  }

  regenerateUrlHandler(format: string) {
    this.urlRegenerateLoading = true;
    const randomAlphaNumericString = this.generateRandomAlphaNumericText(20);
    const body: any = {};
    body[`${format}shareAbleID`] = randomAlphaNumericString;
    this.shareAbleRepo = { ...this.shareAbleRepo, ...body };
    let makeurl = body[`${format}shareAbleID`];
    let url = `${environment.uiUrl}fileViewer/${makeurl}/${format}`;
    this.shareAbleUrl = url;

    this.api.httpURLregenerated(this.shareAbleRepo.id, body).subscribe(
      (resp: any) => {
        this.urlRegenerateLoading = false;
        this.toaster.success('URL Regenerated');
      },
      (error: any) => {
        this.toaster.error('Something wend wrong');
      }
    );
  }

  generaticAction() {
    this.api.httpGeneric().subscribe((resp) => {});
  }

  // Function to render the JSON difference into an unordered list
  renderObject(obj: any, parentList: any) {
    if (typeof obj === 'object') {
      const keys = Object.keys(obj);

      for (const key of keys) {
        const listItem = document.createElement('li');
        listItem.innerHTML = `<strong>${key}</strong>: `;

        if (typeof obj[key] === 'object') {
          const subList = document.createElement('ul');
          subList.classList.add('toggle-ul');

          listItem.appendChild(subList);
          parentList.appendChild(listItem);

          this.renderObject(obj[key], subList);
        } else {
          const value =
            typeof obj[key] === 'object' ? '[object object]' : obj[key];
          listItem.innerHTML += value;
          parentList.appendChild(listItem);
        }
      }
    }
  }

  ngOnInit(): void {
    this.getAllRepos();

    this.store.select('repoDetail').subscribe((element: any) => {
      let obj = {
        access_token: element.repoDetail.access_token,
        createdAt: element.repoDetail.createdAt,
        repoName: element.repoDetail?.repoName,
        userName: element.repoDetail?.userName,
        workspaceName: element.repoDetail?.workspaceName,
        source: element.repoDetail.source,
        projectId: element.repoDetail?.projectId,
        defalutBranch: element.repoDetail?.defalutBranch,
        url: element.repoDetail?.url,
        loading: true,
        branchName: element.repoDetail?.branchName,
        gitlab_name: element.repoDetail?.gitlab_name,
      };

      this.selectedRepodetail = obj;
    });

    if (this.selectedRepodetail) {
      const existingItemIndex = this.repos.findIndex(
        (item: any) => item.repoName === this.selectedRepodetail.repoName
      );

      if (existingItemIndex !== -1) {
        this.repos[existingItemIndex] = {
          ...this.selectedRepodetail,
        };
      } else {
        this.repos.push(this.selectedRepodetail);
      }
    }
    this.formateGenerateHandler();
  }
  openModalWithClass(template: TemplateRef<any>, repo: any) {
    this.shareAbleRepo = repo;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'at-modalalert' })
    );
  }
  openModalWebhookConfiguration(template: TemplateRef<any>, repo: any) {
    this.shareAbleRepo = repo;
    const encoded_id = btoa(repo.id);
    let url = `${environment.apiUrl}${repo.source}_webhook/${encoded_id}`;
    this.webhookUrl = url;
    this.changesWebhookConfigureHandler(repo.id);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'at-modalalert' })
    );
  }
  openShareModal(openModalShare: TemplateRef<any>, repo: any) {
    this.shareAbleRepo = repo;
    this.modalRef = this.modalService.show(
      openModalShare,
      Object.assign({}, { class: 'at-modalalert' })
    );
  }
  openFormatModal(formatModal: TemplateRef<any>, repo: any) {
    this.shareAbleRepo = repo;
    this.formatModalRef = this.FormatemodalService.show(
      formatModal,
      Object.assign({}, { class: 'AtFormateModal' })
    );
  }
  openFormatModalTwo(
    formatModalTwo: TemplateRef<any>,
    url: any,
    fileUrl: any,
    format: any
  ) {
    this.selectedurl = url;
    this.selectedFileUrl = fileUrl;
    this.fileFormate = format;
    this.modalRef = this.modalService.show(
      formatModalTwo,
      Object.assign({}, { class: 'at-formatmodal' })
    );
  }
  versionFileShowHandler(commentDate: any, commitTime: any) {
    this.jsonFileViewerLoading = true;
    const findVersion = this.versionHistoryData.find(
      (commit: any) => commit._id === commentDate
    ).data[commitTime];

    const previosFile = this.api.httpjsonview(
      findVersion.lockFilesHistory[0].previosFile
    );
    const updatedFile = this.api.httpjsonview(
      findVersion.lockFilesHistory[0].updatedFile
    );

    combineLatest(previosFile, updatedFile).subscribe((resp: any) => {
      this.orginalVersionFile = resp[0];
      this.jsonFileViewerLoading = false;

      const jsonList: any = document.getElementById('jsonList');
      const diffResult = this.compareObjects(resp[0], resp[1], 0, jsonList);

      // this.updateVersionFile = diffResult;
      jsonList.innerHTML = '';
      this.renderObject(diffResult, jsonList);

      const commonAncestor: any = document.querySelector('.toggle-ul');
      commonAncestor.addEventListener('click', (e: any) => {
        const target = e.target;
        if (target.tagName === 'UL' && target.classList.contains('toggle-ul')) {
          const liElements = e.target.getElementsByTagName('li');

          if (liElements.length > 0) {
            const firstLi = liElements[0];
            const ulInsideFirstLi = firstLi.querySelector('ul');

            if (ulInsideFirstLi) {
              ulInsideFirstLi.classList.toggle('d-none');
            }
            const spanInsideFirstLi = firstLi.querySelector('span');
            if (spanInsideFirstLi) {
              spanInsideFirstLi.classList.toggle('d-none');
            }
            for (var i = 1; i < liElements.length; i++) {
              liElements[i].classList.toggle('d-none');
            }
          }
        }
      });
    });
  }

  getFormateHistory() {
    const obj = {
      repoName: this.shareAbleRepo.repoName,
      access_token: this.shareAbleRepo.acceess_token,
    };

    this.api.httpGetJsonFilesHistory(obj).subscribe((resp) => {
      let latestChange = resp.result[0].data[0];

      const previosFile = this.api.httpjsonview(
        latestChange.lockFilesHistory[0].previosFile
      );
      const updatedFile = this.api.httpjsonview(
        latestChange.lockFilesHistory[0].updatedFile
      );
      combineLatest(previosFile, updatedFile).subscribe((resp: any) => {
        this.jsonFileViewerLoading = false;
        this.orginalVersionFile = resp[0];

        const jsonList: any = document.getElementById('jsonList');

        const diffResult = this.compareObjects(resp[0], resp[1], 0, jsonList);
        jsonList.innerHTML = '';

        this.renderObject(diffResult, jsonList);

        const commonAncestor: any = document.querySelector('.toggle-ul');
        commonAncestor.addEventListener('click', (e: any) => {
          const target = e.target;
          target.classList.toggle('rotate-class');
          if (
            target.tagName === 'UL' &&
            target.classList.contains('toggle-ul')
          ) {
            const liElements = e.target.getElementsByTagName('li');

            if (liElements.length > 0) {
              const firstLi = liElements[0];
              const ulInsideFirstLi = firstLi.querySelector('ul');

              if (ulInsideFirstLi) {
                ulInsideFirstLi.classList.toggle('d-none');
              }
              const spanInsideFirstLi = firstLi.querySelector('span');
              if (spanInsideFirstLi) {
                spanInsideFirstLi.classList.toggle('d-none');
              }
              for (var i = 1; i < liElements.length; i++) {
                liElements[i].classList.toggle('d-none');
              }
            }
          }
        });
      });

      let hitoryArray: any = [];
      resp.result.forEach((repo: any) => {
        let obj = {
          ...repo,
          historyDate: moment(repo._id).format('ll'),
          data: repo.data.map((item: any) => {
            return {
              ...item,
              createdAt: moment(item.createdAt).format('LT'),
            };
          }),
        };

        hitoryArray.push(obj);
      });
      this.versionHistoryData = hitoryArray;
    });
  }
  openVersionModal(versionHistory: TemplateRef<any>, repo: any) {
    this.shareAbleRepo = repo;
    this.getFormateHistory();
    this.modalRef = this.modalService.show(
      versionHistory,
      Object.assign({}, { class: 'at-versionhistorymodal' })
    );
  }
  openPDFViewModal(pdfView: TemplateRef<any>, pdfType: any, repo?: any) {
    if (repo) {
      this.shareAbleRepo = repo;
    }
    this.selectedShareableFormat = pdfType;
    this.JSONviewLoading = true;

    if (pdfType === 'SBOM') {
      this.api
        .httpjsonview(this.shareAbleRepo.sbomFileUrl)
        .subscribe((data) => {
          this.formatModalRef?.hide();
          this.JSONviewLoading = false;
          this.JSONview = data;
        });
    } else if (pdfType === 'SPDX') {
      this.api
        .httpjsonview(this.shareAbleRepo.spdxFileUrl)
        .subscribe((data) => {
          this.JSONviewLoading = false;
          this.JSONview = data;
          this.formatModalRef?.hide();
        });
    } else if (pdfType === 'CycloneDX') {
      this.api
        .httpjsonview(this.shareAbleRepo.cycloneDxFileUrl)
        .subscribe((data) => {
          this.JSONviewLoading = false;
          this.JSONview = data;
          this.formatModalRef?.hide();
        });
    } else if (pdfType === 'SWID') {
      this.api
        .httpjsonview(this.shareAbleRepo.swidFileUrl)
        .subscribe((data) => {
          this.JSONviewLoading = false;
          this.JSONview = data;
          this.formatModalRef?.hide();
        });
    } else if (pdfType === 'vuln') {
      this.toaster.info('Loading Vulnerability Data...');
      this.grypeisLoading = true;
      this.api.httpjsonview(this.shareAbleRepo.grypeOutputUrl).subscribe((data) => {
        this.JSONviewLoading = false;
        this.JSONview = data;
        this.formatModalRef?.hide();
      });
    }

    this.modalRef = this.modalService.show(
      pdfView,
      Object.assign({}, { class: 'at-viewpdfmodal' })
    );
  }
  openReportModal(repo: any) {
    this.modalRef?.hide();
    let format = repo.CycloneDXshareAbleID ? 'CycloneDX' : 'SPDX';
    let shareableId = repo.CycloneDXshareAbleID || repo.SPDXshareAbleID;
    if (shareableId) {
      let url = `${environment.uiUrl}fileViewer/${shareableId}/${format}`;
      window.open(url, '_blank');
    } else {
      this.toaster.error('No shareable report available for this repository.');
    }
  }
  openUploadModal() {
    const initialState = {
    };
  
    this.modalRef = this.modalService.show(UploadSbomComponent, { initialState, class: 'modal-lg modal-dialog-centered'});

    this.modalRef.content.submit.subscribe(() => {
      this.toaster.success('SBOM uploaded successfully');
      window.location.reload();
    });
  
    this.modalRef.content.close.subscribe(() => {
      this.modalRef?.hide();
    });
  }
  

  ngOnDestroy() {
    this.selectedRepodetail = '';
    this.store.dispatch(new ResetSelectedRepo());
  }
}
