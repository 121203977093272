import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication.service';
import {SocketIoService} from "../../../service/socket-io.service";
import {Store} from "@ngrx/store";
import {User} from "../../../interface/user";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { guidanceContent } from './guidanceContent';
const connection = {
  create: 'Create',
  change: 'Change',
  delete: 'Delete',
}

@Component({
  selector: 'app-nist',
  templateUrl: './nist.component.html',
  styleUrls: ['./nist.component.scss'],
})

export class NistComponent implements OnInit {
  slideConfig = {
    "dots": false,
    "infinite": true,
    "slidesToShow": 2,
    "autoplay": false,
    "showArrow": true,
    "slidesToScroll": 1,
    "autoplaySpeed": 4000,
    "centerPadding": '50px',
  };

  slides = [
    {
      headcount: "PO.1.1: ",
      paragraph: "Identify and document all security requirements for the organization’s software development infrastructures and processes, and maintain the requirements over time.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.1.2: ",
      paragraph: "Identify and document all security requirements for the organization-developed software to meet, and maintain the requirements over time.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.1.3: ",
      paragraph: "Communicate requirements to all third parties who will provide commercial software components to the organization for reuse by the organization’s own software. [Formerly PW.3.1]",
      buttonText: "Pending",
    },
    {
      headcount: "PO.2.1: ",
      paragraph: "Create new roles and alter responsibilities for existing roles as needed to encompass all parts of\n" +
        "  the SDLC. Periodically review and maintain the defined roles and responsibilities, updating them as needed.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.2.2: ",
      paragraph: "Provide role-based training for all personnel with responsibilities that contribute to secure  development. Periodically review personnel proficiency and role-based training, and update the training as needed.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.2.3: ",
      paragraph: "Obtain upper management or authorizing official commitment to secure development,\n" +
        "                      and convey that commitment to all with development-related roles and responsibilities.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.3.1: ",
      paragraph: "Specify which tools or tool types must or should be included in each toolchain to mitigate\n" +
        "                      identified risks, as well as how the toolchain components are to be integrated with each other.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.3.2: ",
      paragraph: "Follow recommended security practices to deploy, operate, and maintain tools and toolchains.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.3.3: ",
      paragraph: "Configure tools to generate artifacts of their support of secure software development practices\n" +
        "                      as defined by the organization.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.4.1: ",
      paragraph: "Define criteria for software security checks and track throughout the SDLC.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.4.2: ",
      paragraph: "Implement processes, mechanisms, etc. to gather and safeguard the necessary information in support\n" +
        "                      of the criteria.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.5.1: ",
      paragraph: "Separate and protect each environment involved in software development.",
      buttonText: "Pending",
    },
    {
      headcount: "PO.5.2: ",
      paragraph: "Secure and harden development endpoints (i.e., endpoints for software designers, developers,\n" +
        "                      testers, builders, etc.) to perform development-related tasks using a risk-based approach.",
      buttonText: "Pending",
    }
  ];

  psSlides = [
    {
      headcount: "PS.1.1: ",
      paragraph: "Protect All Forms of Code from Unauthorized Access and Tampering.",
      buttonText: "Pending",
    },
    {
      headcount: "PS.2.1: ",
      paragraph: "Provide a Mechanism for Verifying Software Release Integrity.",
      buttonText: "Pending",
    },
    {
      headcount: "PS.3.1: ",
      paragraph: "Archive and Protect Each Software Release",
      buttonText: "Pending",
    },
    {
      headcount: "PS.3.2: ",
      paragraph: "Create new roles and alter responsibilities for existing roles as needed to encompass all parts of\n" +
        "  the SDLC. Periodically review and maintain the defined roles and responsibilities, updating them as needed.",
      buttonText: "Pending",
    }
  ];

  pwSlides = [
    {
      headcount: "PW.1.1: ",
      paragraph: "Design Software to Meet Security Requirements and Mitigate Security\n" +
        "                      Risks",
      buttonText: "Pending",
    },
    {
      headcount: "PW.1.2: ",
      paragraph: "Identify and document all security requirements for the organization-developed software to meet, and maintain the requirements over time.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.1.3: ",
      paragraph: "Communicate requirements to all third parties who will provide commercial software components to the organization for reuse by the organization’s own software. [Formerly PW.3.1]",
      buttonText: "Pending",
    },
    {
      headcount: "PW.2.1: ",
      paragraph: "Create new roles and alter responsibilities for existing roles as needed to encompass all parts of\n" +
        "  the SDLC. Periodically review and maintain the defined roles and responsibilities, updating them as needed.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.4.1: ",
      paragraph: "Provide role-based training for all personnel with responsibilities that contribute to secure  development. Periodically review personnel proficiency and role-based training, and update the training as needed.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.4.2: ",
      paragraph: "Obtain upper management or authorizing official commitment to secure development,\n" +
        "                      and convey that commitment to all with development-related roles and responsibilities.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.4.3: ",
      paragraph: "Specify which tools or tool types must or should be included in each toolchain to mitigate\n" +
        "                      identified risks, as well as how the toolchain components are to be integrated with each other.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.5.1: ",
      paragraph: "Follow recommended security practices to deploy, operate, and maintain tools and toolchains.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.6.1: ",
      paragraph: "Configure tools to generate artifacts of their support of secure software development practices\n" +
        "                      as defined by the organization.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.6.2: ",
      paragraph: "Define criteria for software security checks and track throughout the SDLC.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.7.1: ",
      paragraph: "Separate and protect each environment involved in software development.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.7.2: ",
      paragraph: "Secure and harden development endpoints (i.e., endpoints for software designers, developers,\n" +
        "                      testers, builders, etc.) to perform development-related tasks using a risk-based approach.",
      buttonText: "Pending",
    },
    {
      headcount: "PW.8.1: ",
      paragraph: "Test Executable Code to Identify Vulnerabilities and Verify Compliance with Security Requirements",
      buttonText: "Pending",
    },
    {
      headcount: "PW.8.2: ",
      paragraph: "Test Executable Code to Identify Vulnerabilities and Verify Compliance with Security Requirements",
      buttonText: "Pending",
    },
    {
      headcount: "PW.9.1: ",
      paragraph: "Configure Software to Have Secure Settings by Default",
      buttonText: "Pending",
    },
    {
      headcount: "PW.9.2: ",
      paragraph: "Test Executable Code to Identify Vulnerabilities and Verify Compliance with Security Requirements",
      buttonText: "Pending",
    }
  ];

  rvSlides = [
    {
      headcount: "RV.1.1: ",
      paragraph: "Identify and Confirm Vulnerabilities on an Ongoing Basis",
      buttonText: "Pending",
    },
    {
      headcount: "RV.1.2: ",
      paragraph: "Review, analyze, and/or test the software’s\n" +
        "                        code to identify or confirm the presence of previously undetected vulnerabilities.",
      buttonText: "Pending",
    },
    {
      headcount: "RV.1.3: ",
      paragraph: "Have a policy that addresses vulnerability disclosure and remediation,\n" +
        "                        and implement the roles, responsibilities, and processes needed to support that policy.",
      buttonText: "Pending",
    },
    {
      headcount: "RV.2.1: ",
      paragraph: "Analyze each vulnerability to gather sufficient information about risk to plan\n" +
        "                        its remediation or other risk response.",
      buttonText: "Pending",
    },
    {
      headcount: "RV.2.2: ",
      paragraph: "Plan and implement risk responses for vulnerabilities.",
      buttonText: "Pending",
    },
    {
      headcount: "RV.3.1: ",
      paragraph: "Analyze identified vulnerabilities to determine their root causes.",
      buttonText: "Pending",
    },
    {
      headcount: "RV.3.2: ",
      paragraph: "Analyze the root causes over time to identify patterns, such as a particular secure coding\n" +
        "                        practice not being followed consistently.",
      buttonText: "Pending",
    },
    {
      headcount: "RV.3.3: ",
      paragraph: "Review the software for similar vulnerabilities to eradicate a class of vulnerabilities, and\n" +
        "                        proactively\n" +
        "                        fix them rather than waiting for external reports. ",
      buttonText: "Pending",
    },
    {
      headcount: "RV.3.4: ",
      paragraph: "Review the SDLC process, and update it if appropriate to prevent (or reduce the likelihood of)\n" +
        "                        the root cause recurring in updates to the software or in new software that is created.",
      buttonText: "Pending",
    }
  ];

  @ViewChild('openModal') openModal?: ModalDirective;
  text!: string;
  user!: string;
  compliance!: string;

  isFirstOpen = false;
  isPoFirstOpen = true;
  poProgressBar: number = 0;
  poProgressBarStyle: string = '';
  newComment: string = '';
  poName: string[] = [
    'po.1.1',
    'po.1.2',
    'po.1.3',
    'po.2.1',
    'po.2.2',
    'po.2.3',
    'po.3.1',
    'po.3.2',
    'po.3.3',
    'po.4.1',
    'po.4.2',
    'po.5.1',
    'po.5.2',
  ];
  psName: string[] = ['ps.1.1', 'ps.2.1', 'ps.3.1', 'ps.3.2'];
  pwName: string[] = [
    'pw.1.1',
    'pw.1.2',
    'pw.1.3',
    'pw.2.1',
    'pw.4.1',
    'pw.4.2',
    'pw.4.3',
    'pw.5.1',
    'pw.6.1',
    'pw.6.2',
    'pw.7.1',
    'pw.7.2',
    'pw.8.1',
    'pw.8.2',
    'pw.9.1',
    'pw.9.2',
  ];
  rvName: string[] = [
    'rv.1.1',
    'rv.1.2',
    'rv.1.3',
    'rv.2.1',
    'rv.2.2',
    'rv.3.1',
    'rv.3.2',
    'rv.3.3',
    'rv.3.4',
  ];
  poComments: string[] = Array(this.poName.length).fill('');
  actualComments: any[] = [];
  activityLogs: any[] = [];
  psComments: string[] = Array(this.psName.length).fill('');
  pwComments: string[] = Array(this.pwName.length).fill('');
  rvComments: string[] = Array(this.rvName.length).fill('');
  filesToUpload: FormData = new FormData();
  filesToPreview = new Set();
  fileNamesAddedToPreview = new Set();
  openModalName: string = '';
  isOpenAccordion: string[] = [];
  selectedPolicy: string = '';
  hasFiles: string[] = [];

  poPolicy: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  poEvidence: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  psProgressBar: number = 0;
  psProgressBarStyle: string = '';
  psPolicy: boolean[] = [false, false, false, false];
  psEvidence: boolean[] = [false, false, false, false];

  pwProgressBar: number = 0;
  pwProgressBarStyle: string = '';
  pwPolicy: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  pwEvidence: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  rvProgressBar: number = 0;
  rvProgressBarStyle: string = '';
  rvPolicy: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  rvEvidence: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  total = 0;
  completed:any = {};
  totalCompletedCount = 0;
  userData: any;
  orgId: string = '';
  userId: string = '';
  modalRef?: BsModalRef;
  displayAccordion: string = 'PO.1.1';
  headCount: string = '';
  dynamicClass: string = 'Details';
  guidanceContent: any = guidanceContent

  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private util: Util,
    private location: Location,
    private router: Router,
    private auth: AuthenticationService,
    private socketService: SocketIoService,
    private store: Store<{ userData: User }>,
    private modalService: BsModalService
  ) {
    this.socketService.listenToServer(connection.change).subscribe(change => {
      this.onChange(change);
    });
    this.socketService.listenToServer(connection.create).subscribe(data => {
      this.onCreate(data);
    });
    this.socketService.listenToServer(connection.delete).subscribe(data => {
      this.onDelete(data);
    });
    this.store.select('userData').subscribe((userData) => {
      this.userData = userData;
    });
    this.orgId = localStorage.getItem('org_id') || '';
    this.userId = localStorage.getItem('userId') || '';
  }

  onChange(change: any){
    const index = this.actualComments.findIndex(comment => comment._id === change._id);
    this.actualComments[index] = change;
  }

  onCreate(data: any){
    this.actualComments.unshift(data);
  }

  onDelete(data: any){
    this.actualComments = this.actualComments.filter(d => d._id !== data._id);
  }

  openDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm rt-modaldelete'});
  }

  decline(): void {
    this.modalRef?.hide();
  }

  setStatusToComplete(slide: any, index: number) {
    
    if (slide.buttonText === 'Complete'){
      return;
    }
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    const name = slide.headcount.toLowerCase().replace(': ', '');
    const data = {
      title: name.slice(0, 2),
      index: index,
      name: name,
      policy: true,
      evidence: true,
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      this.fetchAllPercistence();
    });

  }
  

  showAccordion(headCount: string) {
    const elements = document.querySelectorAll('.slide.slick-slide');
    elements.forEach(element => {
      element.classList.remove('slick-current', 'slick-active');
    });
    this.displayAccordion = headCount.replace(':', '').trim();
    this.headCount = headCount;
    this.actualComments = [];
    this.fetchCommments();
    this.getActivityLogs();
    this.onAccordionOpen(this.displayAccordion.toLowerCase());
  }

  afterChange(e:any) {
    let headCount = this.slides[e.currentSlide].headcount;
    this.displayAccordion = headCount.replace(':', '').trim();
    this.headCount = headCount;
    this.actualComments = [];
    this.fetchCommments();
    this.getActivityLogs();
    this.onAccordionOpen(this.displayAccordion.toLowerCase());
  }

  ngOnInit(): void {

    // const userData = this.auth.getUserData(); // replace 'userData' with actual property name
    // this.user = this.userData?.org_id || 'default';
    this.compliance = this.userData?.type || 'default';

    //this.util.refreshUser();
    ////console.log("this.util.isPermission('canViewCompliance')", this.util.isPermission('canViewCompliance'));
    if (!this.util.isPermission('canViewCompliance')) {
      this.location.back();
    }

    this.fetchAllPercistence();
    this.onPoChanges();
    this.onPsChanges();
    this.onPwChanges();
    this.onRvChanges();
    this.total =
      (this.poEvidence?.length || 0) +
      (this.psEvidence?.length || 0) +
      (this.pwEvidence?.length || 0) +
      (this.rvEvidence?.length || 0);

    document
      .getElementById('downloadReportButton')
      ?.addEventListener('click', () => {
        const link = document.createElement('a');
        link.href =
          'https://www.cisa.gov/sites/default/files/2024-04/Self_Attestation_Common_Form_FINAL_508c.pdf';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    this.fetchCommments();
    this.getActivityLogs();
  }

  fetchCommments() {
    this.api
      .fetchComments(this.orgId, this.displayAccordion)
      .subscribe((resp: any) => {
        resp.result.forEach((item: { any: any}) => {
          this.actualComments.push(item);
        });
      });
  }

  getActivityLogs() {
    this.activityLogs = [];
    this.api
      .getCommentsLogs(this.orgId, this.displayAccordion)
      .subscribe((resp: any) => {
        resp.result.forEach((item: { any: any}) => {
          this.activityLogs.push(item);
        });
      });
  }
  
  /**
   * @description handle the button text
   * * used to know about the completed status
   * @param compliances 
   * @param slides 
   */
  setSlidersButtonText(compliances = [], slides: any = []){
    let completed:any = {}
    if (compliances.length) {
      compliances.forEach((element: any, index: any) => {
        
        slides.find((x: any, i: any) => {
          if(x.headcount.toLowerCase().replace(': ', '') == element.name){
            if(element.evidence && element.policy){
              slides[i].buttonText = 'Complete';
              this.completed[x.headcount] = "Complete"
              completed[x.headcount] = "Complete"
            }
          }
        });
      });
    }
    const currentSlide = slides[0].headcount.toLowerCase().split('.')[0];
    const tabsIndex = this.tabs.findIndex((e) => e.displayAccordion.toLowerCase().split('.')[0] == currentSlide);

    const progressBar = Math.round((Object.keys(completed).length/slides.length) * 100);
    const completedFraction = Object.keys(completed).length + '/' + slides.length;
    // (value/total value)×100%
    this.tabs[tabsIndex].progressBar = progressBar;
    this.tabs[tabsIndex].progressFraction = completedFraction;
    this.tabs[tabsIndex].progressBarStyle = {width: progressBar.toString()+'%'};
    this.totalCompletedCount = Object.keys(this.completed).length
  }

  fetchAllPercistence() {
    this.api.fetchCompliance('po').subscribe((resp: any) => {
      if (resp.result.complianceData.length != 0) {
        for (let i of resp.result.complianceData) {
          this.poPolicy[i.index] = i.policy;
          this.poEvidence[i.index] = i.evidence;
          this.poComments[i.index] = i.comments;
        }
        this.onPoChanges();
      }
      this.setSlidersButtonText(resp.result.allCompliances, this.slides);
    });
    this.api.fetchCompliance('ps').subscribe((resp: any) => {
      if (resp.result.complianceData.length != 0) {
        for (let i of resp.result.complianceData) {
          this.psPolicy[i.index] = i.policy;
          this.psEvidence[i.index] = i.evidence;
          this.psComments[i.index] = i.comments;
        }
        this.onPsChanges();
      }
      this.setSlidersButtonText(resp.result.allCompliances, this.psSlides);
    });
    this.api.fetchCompliance('pw').subscribe((resp: any) => {
      if (resp.result.complianceData.length != 0) {
        for (let i of resp.result.complianceData) {
          this.pwPolicy[i.index] = i.policy;
          this.pwEvidence[i.index] = i.evidence;
          this.pwComments[i.index] = i.comments;
        }
        this.onPwChanges();
      }
      this.setSlidersButtonText(resp.result.allCompliances, this.pwSlides);
    });
    this.api.fetchCompliance('rv').subscribe((resp: any) => {
      if (resp.result.complianceData.length != 0) {
        for (let i of resp.result.complianceData) {
          this.rvPolicy[i.index] = i.policy;
          this.rvEvidence[i.index] = i.evidence;
          this.rvComments[i.index] = i.comments;
        }
        this.onRvChanges();
      }
      this.setSlidersButtonText(resp.result.allCompliances, this.rvSlides);
    });
  }

  uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files?.length) return;
    ////console.log(target.files.length);
    for (let file = 0; file < target.files.length; file++) {
      this.filesToPreview.add(target.files[file]);
    }

    ////console.log(this.filesToUpload.getAll('file'));

    const filePreview = document.getElementById('file-preview');

    for (const file of this.filesToPreview) {
      try {
        if (file instanceof File) {
          if (this.fileNamesAddedToPreview.has(file.name)) {
            ////console.log("File already added");
            continue;
          }
          let fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);
          ////console.log(fileExtension);
          if (fileExtension === 'exe' || fileExtension === 'jar') {
            this.toaster.error('File type not allowed');
            this.filesToPreview.delete(file);
            continue;
          }
          const fileName = file.name.replace(/[^\w\s.]/gi, ''); // Escape special characters except for '.'
          const fileElement = document.createElement('div');
          if (!filePreview) return;
          fileElement.textContent = fileName;
          fileElement.style.fontSize = '12px';
          fileElement.style.position = 'relative';
          this.fileNamesAddedToPreview.add(file.name);
          const deleteBtn = document.createElement('button');

          // deleteBtn.textContent = 'Delete';
          deleteBtn.style.fontSize = '12px';
          deleteBtn.classList.add('btn', 'button--secondary', 'button--small', 'btn-delete-file');
          deleteBtn.style.marginLeft = '20px';
          deleteBtn.style.marginTop = '10px';

          deleteBtn.addEventListener('click', () => {
            filePreview.removeChild(fileElement);
            this.filesToPreview.delete(file);
            this.fileNamesAddedToPreview.delete(file.name);
            ////console.log(file.name);
          });
          fileElement.appendChild(deleteBtn);
          filePreview.appendChild(fileElement);
        }
      } catch {
        continue;
      }
    }
  }
  onAccordionOpen(policy: string) {
    this.selectedPolicy = policy.toUpperCase();
    console.log(this.selectedPolicy);
    //@ts-ignore
    if (this.isOpenAccordion.includes(policy)) {
      this.isOpenAccordion = this.isOpenAccordion.filter((p) => p !== policy);
      this.hasFiles.push(policy);
      this.filesToPreview = new Set();
      this.fileNamesAddedToPreview.clear();
      this.filesToUpload = new FormData();
      return;
    }
    if (!this.util.isPermission('canViewComplianceAttachments', false)) {
      this.toaster.error("You don't have permission to view attachments");
      return;
    }
    if (!this.hasFiles.includes(policy)) {
      // this.toaster.info('Checking for Evidences..');
      this.api.fetchFile(policy).subscribe((data) => {
        if (data) {
          const keys = Object.keys(data);
          for (let i = 0; i < Object.keys(data).length; i++) {
            try {
              this.createAccordionElement(
                keys[i],
                data[keys[i]].description,
                true,
                policy
              );
            } catch {
              continue;
            }
          }
        } else {
          ////console.log("No data");
        }
      });
    }

    this.isOpenAccordion.push(policy);
  }

  createAccordionElement(
    title: string,
    content: string,
    poPolicy: boolean,
    policy: string
  ): void {
    title = title.replace(/-/g, ' ');

    let accordionHTML = `<div class="col-md-4 my-4 d-inline-block ${title.replace(
      /[^a-zA-Z0-9]/g,
      ''
    )}${policy.replace(/\./g, '')}hide">

      <div class="block block-uploaded-data">
        <h4 class="mb-2">${title}</h4>
        <p>${content}</p>
        <div class="button-group m-0 mt-4">

          <a href="javascript:void(0)">
            <span>
              <i class="fa fa-download ${title.replace(
                /[^a-zA-Z0-9]/g,
                ''
              )}download" aria-hidden="true"></i>
            </span>
          </a>
          <a href="javascript: void(0)">
            <span>
              <i class="fa fa-trash-o ${title.replace(
                /[^a-zA-Z0-9]/g,
                ''
              )}delete" aria-hidden="true"></i>
            </span>
          </a>
          <div class=" icon" accondian-heading>
            <i class="fa" [ngClass]="!${poPolicy} ? 'fa-question-circle-o' : 'fa-check'" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>`;
    let accordionContainer = document.getElementById(
      `accordionContainer${policy}`
    );
    //`accordionContainer${policy}`
    if (!accordionContainer) return;
    accordionContainer.insertAdjacentHTML('beforeend', accordionHTML);
    // if (!accordionContainer.lastElementChild) return;
    // (accordionContainer.lastElementChild as HTMLElement).style.marginRight = '100px !important';
    let deleteButton = accordionContainer.querySelector(
      `.${title.replace(/[^a-zA-Z0-9]/g, '')}delete`
    );
    let downloadButton = accordionContainer.querySelector(
      `.${title.replace(/[^a-zA-Z0-9]/g, '')}download`
    );
    if (!deleteButton || !downloadButton) return;

    if (this.util.isPermission('canDownloadComplianceAttachments', false)) {
      downloadButton.addEventListener('click', () => {
        this.onDownloadFiles(policy, title.replace(/\s/g, '-'));
      });
    } else {
      downloadButton.addEventListener('click', () => {
        this.toaster.error("You don't have permission to download files");
      });
    }
    if (this.util.isPermission('canDeleteComplianceAttachments', false)) {
      deleteButton.addEventListener('click', () => {
        ////console.log("you are here");
        if (!confirm(`Are you sure you want to delete "${title}"?`)) return;
        const data = { policy: policy, title: title.replace(/\s/g, '-') };
        ////console.log(data);
        ////console.log(title);
        ////console.log(policy);
        if (!accordionContainer) return;

        const hideAccordion = document.querySelector(
          `.${title.replace(/[^a-zA-Z0-9]/g, '')}${policy.replace(
            /\./g,
            ''
          )}hide`
        );
        hideAccordion?.classList.add('d-none');

        this.api.deleteComplianceFolder(data).subscribe((data) => {
          if (data.message == 'Folder successfully deleted') {
            ////console.log("success");
            this.toaster.success(`${title} successfully deleted`);
          } else {
            this.toaster.error(
              `${title} failed to delete.. please contact an administrator`
            );
          }
        });
        // window.location.reload();
      });
    } else {
      deleteButton.addEventListener('click', () => {
        this.toaster.error("You don't have permission to delete files");
      });
    }
  }
  //<button class="button button--primary ms-auto" (click)="onDeleteFile('test',)">View files</button> BIG BUTTON FOR VIEW FILES

  onDownloadFiles(policy: string, title: string) {
    this.toaster.success(`Downloading ${title}..`);
    this.api.downloadComplianceFolder(policy, title).subscribe((data) => {
      ////console.log(data);
      ////console.log("downloaded");
      const blob = new Blob([data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = `${title}.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
  onSubmitFiles() {
    if (!this.filesToPreview || this.filesToPreview.size === 0) {
      this.toaster.error('Please select a file to upload');
      return;
    }

    let titleInput = document.getElementById('modal-title') as HTMLInputElement;
    let descriptionInput = document.getElementById(
      'modal-comments'
    ) as HTMLInputElement;
    if (
      !titleInput ||
      !titleInput.value ||
      !descriptionInput ||
      !descriptionInput.value
    ) {
      const errorMessage =
        !titleInput || !titleInput.value
          ? 'Please fill in all required fields: Missing title.'
          : 'Please fill in all required fields: Missing description.';
      this.toaster.error(errorMessage);
      return;
    }
    let title = titleInput.value.trim();
    if (/^\d|[!#$%]/.test(title)) {
      this.toaster.error(
        'Title cannot start with a number or contain characters like !, #, $, %.'
      );
      return;
    }
    let description = descriptionInput.value.trim();
    if (!title || !description) {
      this.toaster.error(
        'Please fill in all required fields (Title and Description)'
      );
      return;
    }
    this.toaster.success('Uploading files..');
    ////console.log("uploading files");
    this.filesToPreview.forEach((file) => {
      if (file instanceof File) {
        this.filesToUpload.append('file', file);
      }
    });

    this.filesToUpload.append('title', title);
    this.filesToUpload.append('description', description);
    this.filesToUpload.append('policy', this.displayAccordion.toLowerCase());
    this.filesToUpload.forEach((value, key) => {
      ////console.log(key, value);
    });
    this.api.muploadFile(this.filesToUpload).subscribe((resp: any) => {
      if (resp.message === 'Files uploaded successfully') {
        this.toaster.success('Files Uploaded Successfully');
        this.createAccordionElement(
          title,
          description,
          true,
          this.displayAccordion.toLowerCase()
        );
        this.getActivityLogs();
      } else {
        this.toaster.error('Error uploading files, please try again');
      }
    });

    this.clearFilePreview();
    titleInput.value = '';
    descriptionInput.value = '';
    this.openModal?.hide();
    this.filesToUpload = new FormData();
    this.filesToPreview = new Set();
    this.fileNamesAddedToPreview = new Set();

    this.hasFiles.push(this.openModalName);
  }

  clearFilePreview() {
    let filePreview = document.getElementById('file-preview');
    this.filesToPreview = new Set();
    this.fileNamesAddedToPreview = new Set();
    this.filesToUpload = new FormData();
    while (filePreview?.firstChild) {
      filePreview.removeChild(filePreview.firstChild);
    }
  }

  onLoadFiles(policy: string) {
    this.api.fetchFile(policy).subscribe((data) => {
      ////console.log(data);
    });
  }

  onPoChanges() {
    if (this.poPolicy == this.poEvidence) {
      var poPolicyLength = this.poPolicy.filter(
        (value: boolean) => value === true
      ).length;
      this.poProgressBar = (poPolicyLength * 100) / this.poPolicy.length;
    } else {
      var count = 0;
      for (let i = 0; i <= this.poPolicy.length; i++) {
        if (this.poPolicy[i] == true && this.poEvidence[i] == true) {
          count += 1;
        }
      }
      this.poProgressBar = (count * 100) / this.poPolicy.length;
    }
    
    this.poProgressBar =
      this.poProgressBar >= 99 ? 100 : Math.round(this.poProgressBar);
    this.poProgressBarStyle = 'width:' + this.poProgressBar.toString() + '%;';
    this.tabs[0].progressBar = this.poProgressBar;
    this.tabs[0].progressBarStyle = {width: this.poProgressBar.toString()+'%'};
  }

  onPsChanges() {
    if (this.psPolicy == this.psEvidence) {
      var psPolicyLength = this.psPolicy.filter(
        (value: boolean) => value === true
      ).length;
      this.psProgressBar = (psPolicyLength * 100) / this.psPolicy.length;
    } else {
      var count = 0;
      for (let i = 0; i <= this.psPolicy.length; i++) {
        if (this.psPolicy[i] == true && this.psEvidence[i] == true) {
          count += 1;
        }
      }
      this.psProgressBar = (count * 100) / this.psPolicy.length;
    }
    this.psProgressBar =
      this.psProgressBar >= 99 ? 100 : Math.round(this.psProgressBar);
    this.psProgressBarStyle = 'width:' + this.psProgressBar.toString() + '%;';
    this.tabs[1].progressBar = this.psProgressBar;
    this.tabs[1].progressBarStyle = {width: this.psProgressBar.toString()+'%'};
  }

  onPwChanges() {
    if (this.pwPolicy == this.pwEvidence) {
      var pwPolicyLength = this.pwPolicy.filter(
        (value: boolean) => value === true
      ).length;
      this.pwProgressBar = (pwPolicyLength * 100) / this.pwPolicy.length;
    } else {
      var count = 0;
      for (let i = 0; i <= this.pwPolicy.length; i++) {
        if (this.pwPolicy[i] == true && this.pwEvidence[i] == true) {
          count += 1;
        }
      }
      this.pwProgressBar = (count * 100) / this.pwPolicy.length;
    }
    this.pwProgressBar =
      this.pwProgressBar >= 99 ? 100 : Math.round(this.pwProgressBar);
    this.pwProgressBarStyle = 'width:' + this.pwProgressBar.toString() + '%;';

    this.tabs[2].progressBar = this.pwProgressBar;
    this.tabs[2].progressBarStyle = {width: this.pwProgressBar.toString()+'%'};
  }

  onRvChanges() {
    if (this.rvPolicy == this.pwEvidence) {
      var rvPolicyLength = this.rvPolicy.filter(
        (value: boolean) => value === true
      ).length;
      this.rvProgressBar = (rvPolicyLength * 100) / this.rvPolicy.length;
    } else {
      var count = 0;
      for (let i = 0; i <= this.rvPolicy.length; i++) {
        if (this.rvPolicy[i] == true && this.rvEvidence[i] == true) {
          count += 1;
        }
      }
      this.rvProgressBar = (count * 100) / this.rvPolicy.length;
    }
    this.rvProgressBar =
      this.rvProgressBar >= 99 ? 100 : Math.round(this.rvProgressBar);
    this.rvProgressBarStyle = 'width:' + this.rvProgressBar.toString() + '%;';

    this.tabs[3].progressBar = this.rvProgressBar;
    this.tabs[3].progressBarStyle = {width: this.rvProgressBar.toString()+'%'};
  }

  OnPoPolicyClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.poPolicy[num]) {
      this.poPolicy[num] = false;
    } else {
      this.poPolicy[num] = true;
    }
    this.onPoChanges();
    var data = {
      title: 'po',
      index: num,
      name: this.poName[num],
      comments: this.poComments[num],
      policy: this.poPolicy[num],
      evidence: this.poEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        ////console.log(typeof(this.poComments[num]))
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.poPolicy[num]) {
          this.poPolicy[num] = false;
        } else {
          this.poPolicy[num] = true;
        }
        this.onPoChanges();
      }
    });
  }

  OnPoEvidenceClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.poEvidence[num]) {
      this.poEvidence[num] = false;
    } else {
      this.poEvidence[num] = true;
    }
    this.onPoChanges();
    var data = {
      title: 'po',
      index: num,
      name: this.poName[num],
      comments: this.poComments[num],
      policy: this.poPolicy[num],
      evidence: this.poEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.poEvidence[num]) {
          this.poEvidence[num] = false;
        } else {
          this.poEvidence[num] = true;
        }
        this.onPoChanges();
      }
    });
  }

  OnPsPolicyClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.psPolicy[num]) {
      this.psPolicy[num] = false;
    } else {
      this.psPolicy[num] = true;
    }
    this.onPsChanges();
    var data = {
      title: 'ps',
      index: num,
      name: this.psName[num],
      comments: this.psComments[num],
      policy: this.psPolicy[num],
      evidence: this.psEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.psPolicy[num]) {
          this.psPolicy[num] = false;
        } else {
          this.psPolicy[num] = true;
        }
        this.onPsChanges();
      }
    });
  }
  OnPsEvidenceClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.psEvidence[num]) {
      this.psEvidence[num] = false;
    } else {
      this.psEvidence[num] = true;
    }
    this.onPsChanges();
    var data = {
      title: 'ps',
      index: num,
      name: this.psName[num],
      comments: this.psComments[num],
      policy: this.psPolicy[num],
      evidence: this.psEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.psEvidence[num]) {
          this.psEvidence[num] = false;
        } else {
          this.psEvidence[num] = true;
        }
        this.onPsChanges();
      }
    });
  }

  OnPwPolicyClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.pwPolicy[num]) {
      this.pwPolicy[num] = false;
    } else {
      this.pwPolicy[num] = true;
    }
    this.onPwChanges();
    var data = {
      title: 'pw',
      index: num,
      name: this.pwName[num],
      comments: this.pwComments[num],
      policy: this.pwPolicy[num],
      evidence: this.pwEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.pwPolicy[num]) {
          this.pwPolicy[num] = false;
        } else {
          this.pwPolicy[num] = true;
        }
        this.onPwChanges();
      }
    });
  }
  OnPwEvidenceClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.pwEvidence[num]) {
      this.pwEvidence[num] = false;
    } else {
      this.pwEvidence[num] = true;
    }
    this.onPwChanges();
    var data = {
      title: 'pw',
      index: num,
      name: this.pwName[num],
      comments: this.pwComments[num],
      policy: this.pwPolicy[num],
      evidence: this.pwEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.pwEvidence[num]) {
          this.pwEvidence[num] = false;
        } else {
          this.pwEvidence[num] = true;
        }
        this.onPwChanges();
      }
    });
  }

  OnRvPolicyClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.rvPolicy[num]) {
      this.rvPolicy[num] = false;
    } else {
      this.rvPolicy[num] = true;
    }
    this.onRvChanges();
    var data = {
      title: 'rv',
      index: num,
      name: this.rvName[num],
      comments: this.rvComments[num],
      policy: this.rvPolicy[num],
      evidence: this.rvEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.rvPolicy[num]) {
          this.rvPolicy[num] = false;
        } else {
          this.rvPolicy[num] = true;
        }
        this.onRvChanges();
      }
    });
  }
  OnRvEvidenceClick(e: Event, num: number) {
    if (!this.util.isPermission('canChangeComplianceStatus', false)) {
      this.toaster.error(
        "You don't have permission to change compliance status"
      );
      return;
    }
    e.stopImmediatePropagation();
    if (this.rvEvidence[num]) {
      this.rvEvidence[num] = false;
    } else {
      this.rvEvidence[num] = true;
    }
    this.onRvChanges();
    var data = {
      title: 'rv',
      index: num,
      name: this.rvName[num],
      comments: this.rvComments[num],
      policy: this.rvPolicy[num],
      evidence: this.rvEvidence[num],
    };
    this.api.setCompliance(data).subscribe((resp: any) => {
      if (resp.code == 0) {
        return;
      } else {
        this.toaster.error('Something went wrong');
        //Revert back the changes as data base is not updated
        if (this.rvEvidence[num]) {
          this.rvEvidence[num] = false;
        } else {
          this.rvEvidence[num] = true;
        }
        this.onRvChanges();
      }
    });
  }
  uploadModalOpen(name: string = '') {
    if (this.util.isPermission('canUploadComplianceAttachments', false)) {
      this.openModal?.show();
      this.openModalName = name;
    } else {
      this.toaster.error(
        "You don't have permission to upload compliance attachments"
      );
    }
  }
  onClickCloseModal() {
    this.openModal?.hide();
    this.clearFilePreview();
    this.filesToUpload = new FormData();
    this.openModalName = '';
    ////console.log("close");
  }

  onSubmitComment(title: string, num: number) {
    if (!this.util.isPermission('canSubmitComplianceComments', false)) {
      this.toaster.error(
        "You don't have permission to add or edit compiance comments"
      );
      return;
    }
    if (title == 'po') {
      // //console.log(this.poComments[num].value)
      var data = {
        title: 'po',
        index: num,
        name: this.poName[num],
        comments: this.poComments[num],
        policy: this.poPolicy[num],
        evidence: this.poEvidence[num],
      };
      this.api.setCompliance(data).subscribe((resp: any) => {
        if (resp.code == 0) {
          this.toaster.success('Comment added Succesfully');
        } else {
          this.toaster.error('Something went wrong');
        }
      });
    } else if (title == 'ps') {
      // //console.log(this.psComments[num])
      var data = {
        title: 'ps',
        index: num,
        name: this.psName[num],
        comments: this.psComments[num],
        policy: this.psPolicy[num],
        evidence: this.psEvidence[num],
      };
      this.api.setCompliance(data).subscribe((resp: any) => {
        if (resp.code == 0) {
          this.toaster.success('Comment added Succesfully');
        } else {
          this.toaster.error('Something went wrong');
        }
      });
    } else if (title == 'pw') {
      // //console.log(this.pwComments[num])
      var data = {
        title: 'pw',
        index: num,
        name: this.pwName[num],
        comments: this.pwComments[num],
        policy: this.pwPolicy[num],
        evidence: this.pwEvidence[num],
      };
      this.api.setCompliance(data).subscribe((resp: any) => {
        if (resp.code == 0) {
          this.toaster.success('Comment added Succesfully');
        } else {
          this.toaster.error('Something went wrong');
        }
      });
    } else {
      // //console.log(this.rvComments[num])
      var data = {
        title: 'rv',
        index: num,
        name: this.rvName[num],
        comments: this.rvComments[num],
        policy: this.rvPolicy[num],
        evidence: this.rvEvidence[num],
      };
      this.api.setCompliance(data).subscribe((resp: any) => {
        if (resp.code == 0) {
          this.toaster.success('Comment added Succesfully');
        } else {
          this.toaster.error('Something went wrong');
        }
      });
    }
  }

  tabs = [
    {
      id: 0,
      label: 'Prepare the Organization (PO)',
      progressBarStyle: { width: '50%' },
      progressFraction: '0/100',
      progressBar: 50,
      displayAccordion: 'PO.1.1'
    },
    {
      id: 1,
      label: 'Protect the Software (PS)',
      progressBarStyle: { width: '50%' },
      progressFraction: '0/100',
      progressBar: 50,
      displayAccordion: 'PS.1.1'
    },
    {
      id: 2,
      label: 'Produce Well-Secured Software (PW)',
      progressBarStyle: { width: '50%' },
      progressFraction: '0/100',
      progressBar: 50,
      displayAccordion: 'PW.1.1'
    },
    {
      id: 3,
      label: 'Respond to Vulnerabilities (RV)',
      progressBarStyle: { width: '50%' },
      progressFraction: '0/100',
      progressBar: 50,
      displayAccordion: 'RV.1.1'
    },
  ];
  selectedTab = this.tabs[0];
  selectTab(tab: any) {
    this.selectedTab = tab;
    this.displayAccordion = tab.displayAccordion;
    this.actualComments = [];
    this.fetchCommments();
    this.getActivityLogs();
  }

  tabsTwo = [
    {
      id: 4,
      label: 'Details',
    },
    {
      id: 5,
      label: 'Comments',
    },
    {
      id: 6,
      label: 'Activity',
    },
  ];

  selectedTabTwo = this.tabsTwo[0];
  selectTabTwo(tab: any) {
    this.selectedTabTwo = tab;
    this.dynamicClass = tab.label;
    // console.log('this.dynamicClass', this.dynamicClass);
  }

  addComment() {
    this.text = this.newComment;

    if (this.newComment) {
      const data = {
        text: this.newComment,
        user: this.userId,
        compliance: this.orgId,
        sliderId: this.displayAccordion
      };
      // console.log("🚀 ~ NistComponent ~ addComment ~ data:", data)

      this.api.postComment(data).subscribe((resp: any) => {
        // console.log("🚀 ~ NistComponent ~ this.api.fetchComments ~ data:",resp.result )
        this.poComments.push(resp.text);
        this.socketService.emitToServer(connection.create, resp.result);
        this.getActivityLogs();
      });

      // this.actualComments.push({text:data.text});
      this.newComment = '';
    }
    // console.log(this.actualComments)
  }
  editComment(editComment: any) {
    // Find the comment with the given ID
    const comment = this.actualComments.find(comment => comment._id ===editComment._id);

    // If the comment was found, set its isEditing property to true
    if (comment) {
      comment.isEditing = true;
    }
  }
  updateComment(updateComment: any) {
    const data = {
      text: updateComment.text,
      user: this.userId,
      compliance: this.orgId,
    };
    const comment = this.actualComments.find(comment => comment._id ===updateComment._id);
    this.api.updateComment(updateComment._id,data).subscribe((response) => {
      // this.socketService.emitToServer(connection.change, {_id:updateComment._id, ...data});
      this.socketService.emitToServer(connection.change, {...updateComment});
      this.getActivityLogs();
    });

    if (comment) {
      comment.isEditing = false;
    }
  }
  cancelEdit(comment: any) {
    // Reset the comment text to its original value
    // comment.text = comment.originalText;

    // Set isEditing back to false
    comment.isEditing = false;
  }

  deleteComment(id: String) {
    this.api.deleteComment(id).subscribe((response) => {
      this.socketService.emitToServer(connection.delete, {_id: id});
      this.getActivityLogs();
    });
    this.modalRef?.hide();
    // this.actualComments = this.actualComments.filter(comment => comment._id !== id);
    // this.poComments.splice(index, 1);
  }
}

