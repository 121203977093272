import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { User, UserState } from '../interface/user';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private userData: any;
  constructor(private store: Store<{ userData: User }>) {
    this.store.select('userData').subscribe((userData) => {
      this.userData = userData;
    });
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.userData.token;
    if (request.url.includes('s3.amazonaws.com') || request.url.includes('amazonaws.com')) {
      return next.handle(request);
    }
    if (!token) {
      return next.handle(request);
    } else {
      const req: HttpRequest<unknown> = request.clone({
        // headers: request.headers
        //   .set('Authorization', `Bearer ${token}`)
        //   .set('ip', '127.0.0.1'),
        setHeaders: {
          Authorization: `Bearer ${token}`,
          // ip: "test",
          // "Access-Control-Allow-Origin": "*"
        },
      });
      // req = request.clone({
      //   headers: request.headers.set("ip", "")
      // });
      return next.handle(req);
    }
  }
}
