import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-sbom',
  templateUrl: './upload-sbom.component.html',
  styleUrls: ['./upload-sbom.component.scss']
})
export class UploadSbomComponent {
  
  @Output() close = new EventEmitter<void>();
  @Output() submit = new EventEmitter<void>();

  projectName: string = '';
  repositoryName: string = '';
  branchName: string = '';
  file: File | null = null;
  fileName: string | null = null;
  uploadError: string | null = null;
  isSubmitting: boolean = false;
  sbomFormat: string = 'CycloneDX';

  constructor(private api: ApiService, private toaster: ToastrService) {}

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
      this.fileName = this.file.name;
      this.uploadError = null;
    }
  }

  onSubmit() {
    if (this.file && this.projectName && this.repositoryName && this.branchName) {
      console.log("Submitting form:", {
        projectName: this.projectName,
        repositoryName: this.repositoryName,
        branchName: this.branchName,
        file: this.file,
      });

      this.toaster.info('Uploading CycloneDx');

      // Prevent multiple submissions
      this.isSubmitting = true; 

      this.api.uploadSbom(this.projectName, this.repositoryName, this.branchName, this.file, this.sbomFormat).subscribe(
        (response) => {
          console.log("Upload successful:", response);
          this.submit.emit();
          this.closeModal();
        },
        (error) => {
          console.error("Upload failed:", error);
          this.uploadError = "Failed to upload SBOM.";
        },
        () => {
          // Reset submitting state in the complete block
          this.isSubmitting = false; 
        }
      );
    } else {
      this.uploadError = "All fields are required.";
    }
  }
  
  closeModal() {
    this.close.emit();
  }
}

