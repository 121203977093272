import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Buffer } from 'buffer';
import { Observable, switchMap } from 'rxjs';
import { API } from '../interface/api';

@Injectable({
  providedIn: 'root',
})
export class RepoServices {
  private httpRepos: HttpClient;

  apiUrl = environment.apiUrl;
  supportApiUrl = environment.supportApiUrl;
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `glpat-GLEmX5iyPKsBhsvuRZiy`,
  });

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpRepos = new HttpClient(handler);
  }

  saveAccessToken(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `savePAToken`, data);
  }
  getAccessToken(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `getAccessToken`);
  }

  fetchGithubProjects(acceess_token: string) {
    return this.httpRepos.get(
      'https://api.github.com/user/repos?page=1&per_page=100',
      {
        headers: {
          Authorization: `Bearer ${acceess_token}`,
        },
      }
    );
  }
  fetchGitlabProjects(acceess_token: string, visibility: string) {
    return this.httpRepos.get(
      `https://gitlab.com/api/v4/projects?visibility=${visibility}&membership=true`,
      {
        headers: {
          Authorization: `Bearer ${acceess_token}`,
        },
      }
    );
  }
  fetchBitBucketProjects(userName: string, appPassword: string, workspaceName: string) {
    return this.httpRepos.get(
      `https://api.bitbucket.org/2.0/repositories/${workspaceName}`,
      {
        headers: {
          Authorization: 'Basic ' + btoa(userName + ':' + appPassword),
        },
      }
    );
  }



  // generateSBOM(acceess_token: string, userName: string, repoName: string) {
  //   return this.httpClient.get(
  //     `https://api.github.com/repos/${userName}/${repoName}/dependency-graph/sbom`,
  //     {
  //       headers: {
  //         Accept: 'application/vnd.github+json',
  //         Authorization: `Bearer ${acceess_token}`,
  //       },
  //     }
  //   );
  // }

  httpSaveGithubRepoDetail(
    access_token: string,
    userName: string,
    repoName: string,
    source: string,
    branchName: string,
    defaultBranch: string
  ) {
    return this.http.post<API>(this.apiUrl + `save-repo-detail`, {
      access_token,
      userName,
      repoName,
      source,
      branchName,
      defaultBranch,
    });
  }
  httpSaveGitlabRepoDetail(obj: any) {
    return this.http.post<API>(this.apiUrl + `save-gitlab-repo-detail`, obj);
  }
  httpSaveBitbuckrtRepoDetail(obj: any) {
    return this.http.post<API>(this.apiUrl + `save-bitbucket-repo-detail`, obj);
  }
  httpAllRepos() {
    return this.http.get<API>(this.apiUrl + `repos`);
  }
  httpGetJsonFilesHistory(obj: any) {
    return this.http.post<API>(this.apiUrl + `getJsonFilesHistory`, obj);
  }
  httpDeleteRepo(obj: any) {
    return this.httpRepos.post(`${this.apiUrl}deleteRepoHandler`, obj, {
      headers: {
        Accept: 'application/vnd.github+json',
      },
    });
  }

  httpGetRepoWithId(id: any, format: any) {
    return this.httpRepos.post(
      `${this.apiUrl}getRepoDetail`,
      { id, format },
      {
        headers: {
          Accept: 'application/vnd.github+json',
        },
      }
    );
  }
  httpjsonview(id: any) {
    return this.httpRepos.get(id);
  }
  httpURLvalidate(obj: any) {
    return this.http.post<API>(this.apiUrl + `repoUrlValidate`, obj);
  }
  httpAlertSetting(obj: any) {
    return this.http.post<API>(this.apiUrl + `alertSetting`, obj);
  }
  httpURLregenerated(id: any, shareAbleID: any) {
    return this.http.post<API>(this.apiUrl + `regenerateRepoUrl`, {
      id,
      shareAbleID,
    });
  }
  httpGrypeScan(id: any) {
    return this.http.post<API>(this.apiUrl + `grypeScan`, { id });
  }
  httpGrantScan(id: any) {
    return this.http.post<API>(this.apiUrl + `grantScan`, { id });
  }

  httpGenerateFormat(obj: any) {
    let body = {
      access_token: obj.access_token,
      userName: obj.userName,
      repoName: obj.repoName,
      format: obj.format,
      SBOMshareAbleID: obj.SBOMshareAbleID,
      CycloneDXshareAbleID: obj.CycloneDXshareAbleID,
      SWIDshareAbleID: obj.SWIDshareAbleID,
      SPDXshareAbleID: obj.SPDXshareAbleID,
      repoUpdateAt: obj.repoUpdateAt,
      defalutBranch: obj.defalutBranch,
      
    };
    //console.log(body, 'body')
    return this.httpRepos.post(`${this.apiUrl}generate-format`, body, {
      headers: {
        Accept: 'application/vnd.github+json',
      },
    });
  }
  httpGenerateGitlabFormat(obj: any) {
    return this.httpRepos.post(
      `${this.apiUrl}generate-gitlab-format`,
      {
        access_token: obj.access_token,
        projectId: obj.projectID,
        repoName: obj.repoName,
        url: obj.url,
        format: obj.format,
        userName: obj.userName,
        SBOMshareAbleID: obj.SBOMshareAbleID,
        CycloneDXshareAbleID: obj.CycloneDXshareAbleID,
        SWIDshareAbleID: obj.SWIDshareAbleID,
        SPDXshareAbleID: obj.SPDXshareAbleID,
        repoUpdateAt: obj.repoUpdateAt,
        gitlab_name: obj.gitlab_name,
      },
      {
        headers: {
          Accept: 'application/vnd.github+json',
          Authorization: `Bearer ${obj.access_token}`,
        },
      }
    );
  }
  fetchAzureProjects(userName: string, appPassword: string) {
    let encodeAcessToken = `Basic ${Buffer.from(`:${appPassword}`).toString(
      'base64'
    )}`;

    return this.httpRepos.get(
      `https://dev.azure.com/${userName}/_apis/git/repositories?api-version=7.1`,
      {
        headers: {
          Authorization: encodeAcessToken,
        },
      }
    );
  }
  httpSaveAzureRepoDetail(body: any) {
    return this.http.post<API>(this.apiUrl + `save-azure-repo-detail`, {
      body,
    });
  }
  httpGenerateAzureFormat(obj: any) {
    return this.httpRepos.post(`${this.apiUrl}generate-azure-format`, obj, {
      headers: {
        Accept: 'application/vnd.github+json',
      },
    });
  }


  httpGenerateBitbucketFormat(obj: any) {
    return this.httpRepos.post(`${this.apiUrl}generate-bitbucket-format`, obj, {
      headers: {
        Accept: 'application/vnd.github+json',
      },
    });
  }
  httpGeneric() {
    return this.httpRepos.post(`${this.apiUrl}genericAction`, {
      headers: {
        Accept: 'application/vnd.github+json',
      },
    });
  }
  fetchBranches(obj: any){
    return this.httpRepos.post(`${this.apiUrl}fetch-branches`, {
      headers: {
        Accept: 'application/vnd.github+json',
      },
      body: obj
    });
  }
}
