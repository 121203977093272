import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  animations: [
    trigger('toggleTable', [
      state('collapsed', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class LicenseComponent implements OnInit {
  @Input() url: string = '';  // URL input to fetch the JSON

  packagesWithLicenses: any[] = [];  // Filtered packages with licenses
  displayedColumns: string[] = ['name', 'version', 'licenseName', 'spdx', 'osiApproved', 'reference'];
  isTableCollapsed: boolean = false;  // Control table visibility

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    if (this.url) {
      this.fetchJsonFromUrl(this.url);
    }
  }

  // Fetch JSON data from the provided URL
  fetchJsonFromUrl(url: string): void {
    this.http.get(url).subscribe(
      (data: any) => {
        if (data && data.results) {
          this.packagesWithLicenses = this.getPackagesWithLicenses(data);
        } else {
          console.error('No data found.');
        }
      },
      (error: any) => {
        console.error('Error fetching JSON:', error);
      }
    );
  }

  // Filter packages that have valid licenses or show no licenses found
  getPackagesWithLicenses(data: any): any[] {
    if (data && data.results) {
      return data.results.filter((pkg: any) => {
        // Check if the license is valid by verifying the presence of spdx_expression or license name
        return pkg.license && pkg.license.spdx_expression.trim() !== "" && pkg.license.name.trim() !== "";
      });
    }
    return [];
  }

  // Toggle the collapse/expand state of the table
  toggleTable(): void {
    this.isTableCollapsed = !this.isTableCollapsed;
  }
}
