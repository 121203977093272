import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AuthComponent } from './component/auth/auth.component';
import { LoginComponent } from './component/auth/login/login.component';
import { SignupComponent } from './component/auth/signup/signup.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './component/auth/forgot-password/forgot-password.component';
import { HomeComponent } from './component/home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { HeaderComponent } from './component/header/header.component';
// import { NavigationComponent } from './shared/Component/navigation/navigation.component';
import { ActivityComponent } from './component/dashboard/activity/activity.component';
// import { PaginationComponent } from './shared/Component/pagination/pagination.component';
import { ProjectsComponent } from './component/dashboard/projects/projects.component';
import { GroupsComponent } from './component/dashboard/groups/groups.component';
import { StoreModule } from '@ngrx/store';
import { userDataReducer } from './store/user.reducer';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { ConfirmEqualValidatorDirective } from './shared/confirm-validator.directive';
import { BillingComponent } from './component/dashboard/billing/billing.component';
import { MonitorComponent } from './component/dashboard/monitor/monitor.component';
import { MonitorDashboardComponent } from './component/dashboard/monitor/monitor-dashboard/monitor-dashboard.component';
import { MonitorAnalyticsPage1Component } from './component/dashboard/monitor/monitor-analytics-page1/monitor-analytics-page1.component';
import { MonitorAnalyticsPage2Component } from './component/dashboard/monitor/monitor-analytics-page2/monitor-analytics-page2.component';
import { AuthInterceptor } from './service/auth-interceptor';
import { MembersComponent } from './component/dashboard/members/members.component';
import { CreateProjectComponent } from './component/dashboard/projects/create-project/create-project.component';
import { ProjectDetailsComponent } from './component/dashboard/projects/project-details/project-details.component';
import { ProfileComponent } from './component/dashboard/profile/profile.component';
import { ManageComponent } from './component/dashboard/manage/manage.component';
import { ManageUserComponent } from './component/dashboard/manage/manage-user/manage-user.component';
import { EditUserComponent } from './component/dashboard/manage/manage-user/edit-user/edit-user.component';
import { ManageRepoConnectionComponent } from './component/dashboard/manage/manage-repo-connection/manage-repo-connection.component';
import { CreateConnectionComponent } from './component/dashboard/manage/manage-repo-connection/create-connection/create-connection.component';
import { GithubConnectionSettingComponent } from './component/dashboard/manage/manage-repo-connection/github-connection-setting/github-connection-setting.component';
import { ManageRoleComponent } from './component/dashboard/manage/manage-role/manage-role.component';
import { EditRoleComponent } from './component/dashboard/manage/manage-role/edit-role/edit-role.component';
import { FaceRecognitionComponent } from './component/auth/face-recognition/face-recognition.component';
import { SharedModule } from './shared/shared.module';
import { AddFaceComponent } from './component/dashboard/add-face/add-face.component';
import { ResetPasswordComponent } from './component/auth/reset-password/reset-password.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { SideBarFilterReducer } from './store/sidebar-filter.reducer';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { NgxCsvParserModule } from 'ngx-csv-parser';


import { DisputeLogComponent } from './component/dashboard/dispute-log/dispute-log.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CartComponent } from './component/dashboard/cart/cart.component';
import { VerifyEmailComponent } from './component/auth/verify-email/verify-email.component';
// import { ReportIssueComponent } from './shared/Component/report-issue/report-issue.component';
import { QuestionerComponent } from './component/auth/questioner/questioner.component';
import { VerifyQuestionerComponent } from './component/auth/verify-questioner/verify-questioner.component';
import { ContactSalesComponent } from './component/dashboard/billing/contact-sales/contact-sales.component';
import { QuestioneryComponent } from './component/auth/questionery/questionery.component';
import { ManageConfigurationComponent } from './component/dashboard/manage/manage-configuration/manage-configuration.component';
import { ManageApiKeysComponent } from './component/dashboard/manage/manage-api-keys/manage-api-keys.component';
import { ViewMoreLogsComponent } from './component/dashboard/activity/view-more-logs/view-more-logs.component';
import { ViewMorePushLogsComponent } from './component/dashboard/activity/view-more-push-logs/view-more-push-logs.component';
import { ChangePasswordComponent } from './component/auth/change-password/change-password.component';
import { MaintenanceComponent } from './component/maintenance/maintenance.component';
import { AgmCoreModule } from '@agm/core';
import { NgChartsModule } from 'ng2-charts';
import { NistComponent } from './component/dashboard/nist/nist.component';
import { ThreatComponent } from './component/dashboard/threat/threat.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsComponentComponent } from './analytics/component/analytics-component/analytics-component.component';
import { AnalyticsPageComponent } from './analytics/component/analytics-page/analytics-page.component';
import { MapComponent } from './map/map.component';
import { MapPageComponent } from './map/component/map-page/map-page.component';
import { MapComponentComponent } from './map/component/map-component/map-component.component';
import { ChangePasswordEulaComponent } from './component/auth/change-password-eula/change-password-eula.component';
import { alertSettingsComponent } from './component/dashboard/alert-settings/alertSettings.component';
import { SbomComponent } from './component/dashboard/sbom/sbom.component';
import { repoReducer } from './store/repo.reducer';
import { RepoTableComponent } from './component/dashboard/repo-table/repo-table.component';
import { SbomdocumentsComponent } from './component/dashboard/sbomdocuments/sbomdocuments.component';
import { SelectrepoComponent } from './component/dashboard/selectrepo/selectrepo.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { FileviewerComponent } from './component/dashboard/fileviewer/fileviewer.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DiffViewerComponent } from './component/dashboard/projects/diff-viewer/diff-viewer.component';
import { SsoSignupComponent } from './component/auth/sso-signup/sso-signup.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterPipe } from './filter.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { VulnerabilityComponent } from './component/dashboard/fileviewer/components/vulnerability/vulnerability.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MarkdownPipe } from './markdown.pipe';
import { LicenseComponent } from './component/dashboard/fileviewer/components/license/license.component';
import { UploadSbomComponent } from './component/dashboard/repo-table/components/upload-sbom/upload-sbom.component';
PlotlyModule.plotlyjs = PlotlyJS;
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    HomeComponent,
    ChangePasswordEulaComponent,
    // NavigationComponent,
    ActivityComponent,
    // PaginationComponent,
    ProjectsComponent,
    GroupsComponent,
    // ConfirmEqualValidatorDirective,
    BillingComponent,
    MonitorComponent,
    MonitorDashboardComponent,
    MonitorAnalyticsPage1Component,
    MonitorAnalyticsPage2Component,
    MembersComponent,
    CreateProjectComponent,
    ProjectDetailsComponent,
    ProfileComponent,
    ManageComponent,
    ManageUserComponent,
    EditUserComponent,
    ManageRepoConnectionComponent,
    CreateConnectionComponent,
    GithubConnectionSettingComponent,
    ManageRoleComponent,
    EditRoleComponent,
    FaceRecognitionComponent,
    AddFaceComponent,
    ResetPasswordComponent,
    DisputeLogComponent,
    CartComponent,
    VerifyEmailComponent,
    // ReportIssueComponent,
    QuestionerComponent,
    DiffViewerComponent,
    VerifyQuestionerComponent,
    ContactSalesComponent,
    QuestioneryComponent,
    ManageConfigurationComponent,
    ManageApiKeysComponent,
    ViewMoreLogsComponent,
    ViewMorePushLogsComponent,
    ChangePasswordComponent,
    MaintenanceComponent,
    NistComponent,
    ThreatComponent,
    AnalyticsComponent,
    AnalyticsComponentComponent,
    AnalyticsPageComponent,
    MapComponent,
    MapPageComponent,
    MapComponentComponent,
    alertSettingsComponent,
    SbomComponent,
    RepoTableComponent,
    SbomdocumentsComponent,
    SelectrepoComponent,
    FileviewerComponent,
    SsoSignupComponent,
    FilterPipe,
    VulnerabilityComponent,
    MarkdownPipe,
    LicenseComponent,
    UploadSbomComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgChartsModule,
    ClipboardModule,
    NgxJsonViewerModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    SlickCarouselModule,
    MatToolbarModule,
    MatCardModule,
    MatTableModule,
    MatFormFieldModule,
    MatDividerModule,
    MatButtonModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,
    ScrollingModule,
    MatSelectModule,
    MatButtonToggleModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
    }),
    StoreModule.forRoot({
      //@ts-ignore
      userData: userDataReducer,
      //@ts-ignore
      sideBarFilter: SideBarFilterReducer,
      //@ts-ignore
      repoDetail: repoReducer,
    }),
    ToastrModule.forRoot(),
    FormsModule,

    ReactiveFormsModule,
    HttpClientModule,
    NgOtpInputModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    LottieModule.forRoot({ player: playerFactory }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    SharedModule,
    PlotlyModule,
    NgxCsvParserModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    // AgmCoreModule.forRoot({
    //   apiKey:''
    // }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBu9NTpSvWu49ln0m38VxYSpCfFxS3OkBU',
      language: 'en',
      libraries: ['places'],
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
