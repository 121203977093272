<div class="mb-5">
  <!-- Use Bootstrap classes for layout -->
  <div class="mb-4 d-flex align-items-center justify-content-between">
    <!-- Left Side: Back Link and Project Details -->
    <div class="d-flex align-items-center">
      <a class="back me-3" href="javascript:void(0)" (click)="onBack()">
        <img src="/assets/images/icon__arrow--left.svg" alt="" />
      </a>
      <div>
        <h4 class="mb-1 d-flex align-items-center">
          Project Details
          <a
            href="javascript:void(0)"
            class="ms-3 color-white"
            tooltip="Refresh"
            placement="bottom"
            (click)="onRefresh()"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </a>
        </h4>
      </div>
    </div>
    <div *ngIf="selectedProjects.length > 0">
      <a
        href="javascript:void(0)"
        class="btn btn-success me-2"
        tooltip="Approve"
        (click)="sendOtpForMultiSelect('Approval')"
      >
        <i class="fa fa-check" aria-hidden="true"></i> Approve
      </a>
      <a
        href="javascript:void(0)"
        class="btn btn-danger"
        tooltip="Disapprove"
        (click)="sendOtpForMultiSelect('Disapproval')"
      >
        <i class="fa fa-times" aria-hidden="true"></i> Disapprove
      </a>
    </div>
  </div>
  <div class="table-main" *ngIf="projectInfo">
    <div class="table-responsive">
      <table class="table table-vmiddle m-0">
        <thead>
          <tr>
            <th scope="col">Project Name</th>
            <th scope="col">Project ID</th>
            <th scope="col">Hash Function</th>
            <th scope="col">Provider</th>
            <th scope="col">Repository Name</th>
            <th scope="col">Hash Insert Lines</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ projectInfo.name }}</td>
            <td>{{ projectInfo._id }}</td>
            <td>{{ projectInfo.hash_function }}</td>
            <td>{{ projectInfo.provider }}</td>
            <td>{{ projectInfo.repository_name }}</td>
            <td>{{ projectInfo.lines }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<tabset class="tab-compact" #manuTabs>
  <tab heading="Repository" (selectTab)="changeTab($event)">
    <div class="table-main mb-4">
      <div class="table-responsive">
        <table class="table table-vmiddle m-0">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Sl. No.</th>
              <th scope="col" style="width: 25%">Scan Name</th>
              <th scope="col">Commited By</th>
              <th scope="col">Branch</th>
              <th scope="col" class="text-center">Scan Status</th>
              <th scope="col">Trigger Point</th>
              <th scope="col">Trigger Time</th>
              <th scope="col">Time Taken to Scan</th>
              <th scope="col">Dispute Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="repoScans.length === 0 && isProcessing">
              <td class="text-center" colspan="7">No Items Found</td>
            </tr>
            <tr
              *ngFor="let scan of repoScans; index as i"
              [ngClass]="{
                disputed:
                  scan.pending_dispute > 0 ||
                  (scan.pendingcommits &&
                    scan.pendingcommits.isCommitPushed === false) ||
                  scan.approved === false ||
                  scan.disapproved === true ||
                  (!scan.approved && scan.trigger_point === 'Webhook'),
                cli: scan.pendingcommits
              }"
            >
              <td>
                <div class="form-checkbox">
                  <input
                    type="checkbox"
                    name="radio_scans"
                    id=""
                    (change)="onSelectProject(scan._id)"
                    [checked]="isProjectSelected(scan._id)"
                  />
                  <span class="icon"></span>
                </div>
              </td>
              <td>{{ (page - 1) * 8 + i + 1 }}</td>
              <td (click)="goToScanDetails(i)">
                <div class="d-flex align-items-center">
                  <a
                    href="javascript:void(0)"
                    class="weight-600 d-flex align-items-center"
                    >{{ scan.name }}
                    <span
                      *ngIf="scan.pendingcommits"
                      class="badge bg-warning status__cli text-dark px-1 mx-3"
                      >CLI</span
                    ></a
                  >
                  <span
                  *ngIf="(scan.pending_dispute > 0 || (scan.pendingcommits && scan.pendingcommits.isCommitPushed === false)) && scan.trigger_point !== 'GitHook'"
                  tooltip="Dispute Is pending For Approval"
                    class="text-danger icon__dispute ms-auto"
                  >
                    <svg
                      height="1792"
                      viewBox="0 0 1792 1792"
                      width="1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </td>
              <td>{{ scan.commitUserName }} ( {{ scan.commitUserEmail }} )</td>
              <td>{{ scan.branch }}</td>
              <td class="text-center">
                <span
                  class="badge px-2"
                  [class]="
                    scan.status === 'Success'
                      ? 'status__success bg-success'
                      : scan.status === 'Failed'
                      ? 'status__failed bg-danger'
                      : 'status__processing bg-info'
                  "
                >
                  {{ scan.status }}
                </span>
              </td>

              <td>{{ scan.trigger_point }}</td>
              <td>{{ scan.createdAt | date : "medium" }}</td>
              <td>
                {{ scan.duration ? (scan.duration / 1000).toFixed(2) : 0 }}s
              </td>
              <td>{{ scan.dispute_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <app-pagination
        [count]="total"
        (changePage)="onChangePage($event)"
        [perPage]="10"
      >
      </app-pagination>
    </div>
  </tab>
  <tab heading="Server" (selectTab)="changeTab($event)">
    <div class="table-main mb-4">
      <div class="table-responsive">
        <table class="table table-vmiddle m-0">
          <thead>
            <tr>
              <th scope="col">Sl. No.</th>
              <th scope="col" style="width: 25%">Scan Name</th>
              <th scope="col" class="text-center">Scan Status</th>
              <th scope="col">Trigger Time</th>
              <th scope="col">Has Dispute</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="serverScans.length === 0 && isProcessing">
              <td class="text-center" colspan="6">No Items Found</td>
            </tr>
            <tr *ngFor="let scan of serverScans; index as i">
              <td>{{ (page - 1) * 8 + i + 1 }}</td>

              <td>
                <a href="javascript:void(0)" class="weight-600">{{
                  scan.name
                }}</a>
              </td>
              <td class="text-center">
                <span
                  class="badge px-2"
                  [class]="
                    scan.status === 'Success'
                      ? 'status__success bg-success'
                      : scan.status === 'Failed'
                      ? 'status__failed bg-danger'
                      : 'status__processing bg-info'
                  "
                >
                  {{ scan.status }}
                </span>
              </td>
              <td>{{ scan.createdAt | date : "medium" }}</td>
              <td>{{ scan.hasDispute }}</td>
              <td>
                <div
                  class="button-group justify-content-center"
                  *ngIf="!scan.approved && !scan.notApproved"
                >
                  <a
                    href="javascript:void(0)"
                    class="button button--icon"
                    tooltip="Approve"
                    (click)="
                      onApproveSingleForOTP(scan._id);
                      $event.stopImmediatePropagation()
                    "
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="button button--icon"
                    tooltip="Disapprove"
                    (click)="
                      onDisApproveSingleForOTP(scan._id);
                      $event.stopImmediatePropagation()
                    "
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <app-pagination
        [count]="total"
        (changePage)="onChangePage($event)"
        [perPage]="10"
      >
      </app-pagination>
    </div>
  </tab>
</tabset>

<app-otp-approval
  (otpEvent)="
    type === 'Approval' ? onApproveSingle($event) : onDisApproveSingle($event)
  "
  (modalEvent)="modalStatusChange($event)"
  [isOpen]="isOTPModalOpen"
></app-otp-approval>

<app-otp-approval
  (otpEvent)="
    type === 'Approval' ? onApprove($event) : onDisapprove($event)
  "
  (modalEvent)="modalStatusChange($event)"
  [isOpen]="isOTPMultiModalOpen"
></app-otp-approval>